import request from '@/utils/request'


// 查询签到配置列表
export function listSignConfig(query) {
  return request({
    url: '/signConfig/signConfigs/list',
    method: 'get',
    params: query
  })
}

// 查询签到配置分页
export function pageSignConfig(query) {
  return request({
    url: '/signConfig/signConfigs/page',
    method: 'get',
    params: query
  })
}

// 查询签到配置详细
export function getSignConfig(data) {
  return request({
    url: '/signConfig/signConfigs/detail',
    method: 'get',
    params: data
  })
}

// 新增签到配置
export function addSignConfig(data) {
  return request({
    url: '/signConfig/signConfigs/add',
    method: 'post',
    data: data
  })
}

// 修改签到配置
export function updateSignConfig(data) {
  return request({
    url: '/signConfig/signConfigs/edit',
    method: 'post',
    data: data
  })
}

// 删除签到配置
export function delSignConfig(data) {
  return request({
    url: '/signConfig/signConfigs/delete',
    method: 'post',
    data: data
  })
}
